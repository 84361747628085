
import { ToolService } from '@app/service/utils';
import { Component, OnInit, ViewEncapsulation } from  '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { DOMAINS } from "app/utils/const-domain";


@Component({
    selector: 'app-chat-genesys',
    templateUrl: './chat-genesys.component.html'
})
export class ChatGenesysComponent implements OnInit {

    scriptsGenesyChat = [
        "https://apps.mypurecloud.ie/widgets/9.0/cxbus.min.js",
        "assets/scripts/genesys-chat/genesys-chat.js"
    ];

    titulo: string;
    // public showLogo: boolean = true;
    constructor(private toolService: ToolService,
        private translate: TranslateService
    ) {
        this.translate.get('chatgenesys.btn_titulo')
            .subscribe(
                text => {
                    this.titulo = text;
                }
            );
        // this.showLogo = !(sessionStorage.getItem('dominio') == DOMAINS.MANA);
    }

    

    ngOnInit() {
        this.addScriptsInBody();
    }

    /**
     * Funcion que se encarga de agregar al body los scripts necesarios
     * para cargar el chat de genesys
     */
    private addScriptsInBody() {
        let body = <HTMLDivElement> document.body;
        this.scriptsGenesyChat.map(
            (url: string) => {
                this.toolService.addScriptInContainer(
                    body,
                    {
                        innerHTML: "",
                        id: "",
                        src: url,
                        async: false,
                        defer: true
                    }
                );
            }
        );
        
    }
}