<intm-container-notification></intm-container-notification>
<intm-loader></intm-loader>
<router-outlet />
<app-scripts/>

@if(origin() === ORIGIN_APP.INTERMUNDIAL){
    @if(chatService.showChat()){
        <app-chat-genesys></app-chat-genesys>
    }
}

