import { Component } from "@angular/core";
import { ChatService } from "@app/service/utils/chat.service";
@Component({
    selector: "public-forms-layout",
    template: `
        <div class="grid min-h-screen">
            <router-outlet></router-outlet>
        </div>
    `
})
export class PublicFormsLayoutComponent {

    constructor(
        private chatService: ChatService
    ) { 
        this.chatService.hide();
    }
 }